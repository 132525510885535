<template>
  <div class="text-center">
    <v-dialog
      class="dialogBox"
      data-cy="dialogBox"
      v-model="showDialog"
      :persistent="true"
      max-width="340"
      @keydown.enter="clickHandler()"
    >
      <div class="addToCartBox" style="display: flex; align-items: center; flex-direction: column">
        <img src="@/assets/zeplin/question-1.svg" alt="Message" />
        <div style="margin-bottom: 10px">
          <h3 style="font-size: 18px">{{ messageTitle }}</h3>
          <p>{{ messageContent }}</p>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            dark
            rounded
            x-large
            :class="messageClass"
            @click="clickHandler()"
            data-cy="dialogConfirm"
          >
            <h3>{{ this.$t("dialogButton.confirm") }}</h3>
          </v-btn>
          <v-btn
            depressed
            dark
            rounded
            x-large
            color="#b92d32"
            :class="messageClass"
            @click="cancelHandler()"
            v-if="allowCancel"
            data-cy="dialogCancel"
          >
            <h3>{{ this.$t("dialogButton.cancel") }}</h3>
          </v-btn>
        </v-card-actions>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    messageTitle: {
      type: String,
      required: false
    },
    messageContent: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    messageClass: {
      type: String,
      required: false,
      default: "primary"
    },
    destination: {
      type: String,
      required: false,
      default: ""
    },
    allowCancel: {
      type: Boolean,
      required: false,
      default: false
    },
    emitEventConfirm: {
      type: String,
      required: false,
      default: null
    },
    emitEventPayload: {
      required: false,
      default: null
    }
  },
  data: () => ({
    showDialog: false
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  watch: {
    value: function (val) {
      if (val === true) {
        if (this.messageClass) {
          this.showDialog = true;
        }
      }
    }
  },
  methods: {
    clickHandler() {
      this.showDialog = false;
      this.dialog = false;
      if (this.destination !== "") {
        if (this.destination === "-1") {
          // page back
          this.$router.go(-1);
        } else if (this.destination === "0") {
          // reload
          this.$router.go();
        } else {
          this.$router.push(this.destination);
        }
      }
      if (this.emitEventConfirm) {
        this.$emit(this.emitEventConfirm, this.emitEventPayload);
      }
    },
    cancelHandler() {
      this.showDialog = false;
      this.dialog = false;
      if (this.destination !== "") {
        if (this.destination === "-1") {
          // page back
          this.$router.go(-1);
        } else if (this.destination === "0") {
          // reload
          this.$router.go();
        }
      }
      this.$emit("cancel");
    }
  }
};
</script>
