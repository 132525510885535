<template>
  <div class="loginCard">
    <div id="backgroundImage"></div>
    <div class="signupStepper">
      <div class="formGroup" style="flex: 1">
        <h1>{{ $t("forgotEmail") }}</h1>
        <v-text-field
          outlined=""
          v-model="email"
          :error-messages="emailErrors"
          :label="$t('signEmailBox')"
          required
          @input="emailInputEvent"
          @blur="$v.email.$touch()"
          @keydown.enter="handleEnter"
        ></v-text-field>
        <div v-if="codeSent">
          <div>
            <v-text-field
              outlined
              v-model="code"
              messages=""
              prefix="E -"
              inputmode="numeric"
              pattern="[0-9]*"
              maxlength="6"
              type="text"
              :label="$t('profileBasicEmailCode')"
              @keydown.enter="handleEnter"
              hide-details
            ></v-text-field>
            <p v-if="resendAlert">
              {{ $t("loginForgotResend") }}
            </p>
            <p v-else-if="countDown > 0">
              {{ this.$t("signWait", { countDown: this.countDown }) }}
            </p>
            <p v-else>
              <a @click="sendEmailCode">
                {{ this.$t("signResend") }}
              </a>
            </p>
          </div>
        </div>

        <MessageBox
          v-model="messageBox.state"
          :message-content="messageBox.content"
          :message-class="messageBox.cssClass"
          :destination="messageBox.destination"
          :emitEventConfirm="messageBox.confirmEvent"
          @backToLogin="$emit('back')"
        />
      </div>

      <div class="buttonGroup">
        <v-btn rounded @click="back" x-large depressed class="mr-2"
          ><p>{{ $t("signBack") }}</p></v-btn
        >
        <v-btn depressed rounded x-large color="primary" @click="triggerSendCode" v-if="!codeSent" :loading="loading"
          ><p>{{ $t("signSend") }}</p></v-btn
        >
        <v-btn
          rounded
          color="primary"
          @click="submit"
          :disabled="!allowSubmit"
          x-large
          depressed
          :loading="loading"
          v-else
          ><p>{{ $t("profilePersonConfirm") }}</p></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { sendResetPasswordVerificationCode, checkEmailVerificationCode } from "@/services/authService";
import config from "@/config.js";
import MessageBox from "@/components/Common/MessageBox";
import { required, email, minLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { sendResetPasswordRequest } from "../../services/authService";

export default {
  data: () => ({
    code: "",
    countDown: 0,
    password: "",
    rePassword: "",
    show1: false,
    show2: false,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    email: "",
    codeSent: false,
    loading: false,
    resendAlert: false
  }),
  components: {
    MessageBox
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    code: {
      minLength: minLength(6),
      containsNumber: function (value) {
        return /[0-9]/.test(value);
      }
    }
  },
  mounted() {
    if (this.$store.state.signupForm.email !== "") {
      this.codeSent = true;
    }
    this.email = this.$store.state.signupForm.email;
  },
  methods: {
    async submit() {
      this.loading = true;
      const payload = {
        email: this.email,
        emailVerificationCode: this.code
      };
      const result = await sendResetPasswordRequest(this.$axios, config, payload);
      if (result.status === 200) {
        this.messageBox.content = this.$t("dialogMsg.forgot.success");
        this.messageBox.cssClass = "message";
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.messageBox.confirmEvent = "backToLogin";
      } else if (result.status === 400) {
        this.messageBox.content = this.$t("errorMsg.basicProfile.emailVerifyWrong");
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
      }
      this.loading = false;
    },
    back() {
      this.$emit("back");
    },
    async triggerSendCode() {
      this.loading = true;
      this.$v.email.$touch();
      if (!this.$v.email.$invalid && this.countDown === 0) {
        await this.sendEmailCode();
      }
      this.loading = false;
    },
    emailInputEvent() {
      this.codeSent = false;
      this.code = "";
    },
    enableSubmit(code) {
      this.code = code;
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    async sendEmailCode() {
      let language;
      switch (this.$i18n.locale) {
        case "en":
          language = 0;
          break;
        case "zhHant":
          language = 1;
          break;
        case "zhHans":
          language = 2;
          break;
        default:
          language = 0;
      }
      const params = {
        language: language
      };
      const result = await sendResetPasswordVerificationCode(this.$axios, config, this.email, params);

      if (
        result.status === 200 ||
        (result.status === 500 && result.data.message === "UNIQUE constraint failed: t_email_code.email")
      ) {
        this.countDown = 60;
        this.countDownTimer();
        this.codeSent = true;
        const payload = {
          emailVerifyCount: this.$store.state.signupForm.emailVerifyCount + 1
        };
        if (result.status === 500 && result.data.message === "UNIQUE constraint failed: t_email_code.email") {
          this.resendAlert = true;
        }
        this.$store.commit("editSignupForm", payload);
      } else if (result.status === 400 && result.data.message === "The email is not registered.") {
        this.messageBox.content = this.$t("errorMsg.login.emailNotExist");
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
      }
    },
    async checkVerificationCode() {
      const result = await checkEmailVerificationCode(this.$axios, config, this.email, this.code);

      if (result.status === 200) {
        if (result.data.isMatch) {
          return true;
        } else {
          this.messageBox.content = this.$t("errorMsg.signup.emailVerifyWrong");
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
          return false;
        }
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
        return false;
      }
    },
    handleEnter() {
      if (!this.codeSent) {
        this.triggerSendCode();
      } else if (this.allowSubmit) {
        this.submit();
      }
    }
  },
  computed: {
    allowSendCode() {
      return !this.$v.$invalid && this.countDown === 0;
    },
    allowSubmit() {
      return !this.$v.$invalid && this.codeSent && this.code.length === 6;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("errorMsg.signup.emailWrong"));
      !this.$v.email.required && errors.push(this.$t("errorMsg.signup.emailEmpty"));
      return errors;
    },
    rules() {
      return {
        required: (value) => !!value || this.$t("errorMsg.signup.passwordEmpty"),
        min: (v) => v.length >= 8 || this.$t("errorMsg.signup.passwordFormat"),
        containsUppercase: (v) => /[A-Z]/.test(v) || this.$t("errorMsg.signup.passwordFormat"),
        containsLowercase: (v) => /[a-z]/.test(v) || this.$t("errorMsg.signup.passwordFormat"),
        containsNumber: (v) => /[0-9]/.test(v) || this.$t("errorMsg.signup.passwordFormat")
      };
    },
    pwMatch() {
      return this.password === this.rePassword || this.$t("errorMsg.signup.passwordNotMatch");
    },
    pwErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t("errorMsg.signup.passwordEmpty"));
      !this.$v.password.containsUppercase && errors.push(this.$t("errorMsg.signup.passwordFormat"));
      !this.$v.password.containsLowercase && errors.push(this.$t("errorMsg.signup.passwordFormat"));
      !this.$v.password.containsNumber && errors.push(this.$t("errorMsg.signup.passwordFormat"));

      return errors;
    }
  }
};
</script>

<style></style>
