<template>
  <div class="floatingBox">
    <div class="signupForm">
      <LoginForm @forgot="forgotPassword = !forgotPassword" v-if="!forgotPassword" />
      <forgot-password @back="forgotPassword = !forgotPassword" v-else />
    </div>
  </div>
</template>

<script>
import LoginForm from "../components/Login/LoginForm";
import Landing from "../layouts/Landing";
import ForgotPassword from "../components/Login/ForgotPassword.vue";

export default {
  name: "Login",
  components: {
    LoginForm,
    ForgotPassword
  },
  data: () => ({
    forgotPassword: false
  }),
  created() {
    this.$emit(`update:layout`, Landing);
  }
};
</script>
