<template>
  <v-card flat class="text-center" width="100%">
    <v-toolbar flat color="rgba(0, 0, 0, 0)">
      <div class="hidden-md-and-up">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
          </template>
          <v-card>
            <v-btn :ripple="false" text block @click="toContact">
              {{ footerArray[0] }}
            </v-btn>
            <v-btn :ripple="false" text block @click="toTerms">
              {{ footerArray[1] }}
            </v-btn>
            <v-btn :ripple="false" text block @click="toPrivacy">
              {{ footerArray[2] }}
            </v-btn>
            <v-btn :ripple="false" text block @click="toCookies">
              {{ footerArray[3] }}
            </v-btn>
          </v-card>
        </v-menu>
      </div>
      <div class="v1 hidden-sm-and-down" style="height: 100%">
        <v-btn :ripple="false" text style="height: 100%" @click="toContact">
          <span style="font-weight: 700; color: #54565a">{{ footerArray[0] }}</span>
        </v-btn>
        <v-btn :ripple="false" text style="height: 100%" @click="toTerms">
          <span style="font-weight: 700; color: #54565a">{{ footerArray[1] }}</span>
        </v-btn>
        <v-btn :ripple="false" text style="height: 100%" @click="toPrivacy">
          <span style="font-weight: 700; color: #54565a">{{ footerArray[2] }}</span>
        </v-btn>
        <v-btn :ripple="false" text style="height: 100%" @click="toCookies">
          <span style="font-weight: 700; color: #54565a">{{ footerArray[3] }}</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <social-buttons :size="$screen.width < 600 ? 36 : 24" />
    </v-toolbar>
  </v-card>
</template>

<script>
import SocialButtons from "./SocialButtons.vue";
export default {
  components: { SocialButtons },
  name: "Footer",
  data: () => ({
    icons: ["fab fa-facebook", "fab fa-twitter", "fab fa-instagram"]
  }),
  computed: {
    footerArray() {
      const translateArray = (item) => {
        const content = this.$t(item);
        const result = content.replace(/['"]+/g, "").replace(/['“]+/g, "").replace(/['”]+/g, "").split(",");
        return result;
      };

      const result = translateArray("landingFootage");
      return result;
    }
  },
  methods: {
    toContact() {
      this.$store.commit("updateCurrentPage", null);
      this.$router.push("/contact");
    },
    toTerms() {
      this.$store.commit("updateCurrentPage", null);
      this.$router.push("/terms");
    },
    toPrivacy() {
      this.$store.commit("updateCurrentPage", null);
      this.$router.push("/privacy");
    },
    toCookies() {
      this.$store.commit("updateCurrentPage", null);
      this.$router.push("/cookies");
    }
  }
};
</script>
