<template>
  <div class="loginCard">
    <div id="backgroundImage"></div>
    <div class="signupStepper" style="height: 100%">
      <h1>{{ $t("loginHeading") }}</h1>

      <form class="mb-10">
        <v-text-field
          outlined=""
          v-model="email"
          :error-messages="emailErrors"
          :label="$t('loginEmail')"
          @input="clearError"
          @keyup.enter="signIn"
          data-cy="email"
          required
        ></v-text-field>

        <v-text-field
          outlined=""
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          name="input-10-2"
          :label="$t('loginPassword')"
          value=""
          class="input-group--focused"
          @click:append="show1 = !show1"
          @input="clearError"
          @keyup.enter="signIn"
          data-cy="password"
        ></v-text-field>

        <v-alert dense type="error" width="100%" outlined v-if="messageBox.state">
          {{ messageBox.content }}
        </v-alert>

        <a @click="$emit('forgot')"
          ><p>{{ $t("loginForgot") }}</p></a
        >

        <v-btn
          rounded
          color="primary"
          x-large=""
          @click="signIn"
          depressed
          :ripple="false"
          :loading="loading"
          data-cy="login"
          ><span style="font-weight: 600"> {{ $t("login") }}</span>
        </v-btn>
      </form>
      <div>
        <v-divider></v-divider>

        <router-link to="/signup" data-cy="signup">
          <p style="margin-top: 30px">{{ $t("loginSign") }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { userSignIn } from "@/services/authService";
import config from "@/config.js";
import { getKeyPair } from "../../services/authService";

export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
    select: { required },
    checkbox: {
      checked(val) {
        return val;
      }
    }
  },
  name: "LoginForm",

  data: () => ({
    email: "",
    checkbox: false,
    show1: false,
    show2: false,
    password: "",
    error: false,
    error2: false,
    error3: false,
    error4: false,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    loading: false
  }),

  computed: {
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("Item is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    }
  },

  methods: {
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    },
    async signIn() {
      this.loading = true;
      const result = await userSignIn(this.$axios, config, this.email.toLowerCase(), this.password);

      if (result.status === 200) {
        this.$session.start();
        this.$session.set("token", result.data.token);
        this.$session.set("email", this.email);
        this.$session.set("role", "member");
        this.$store.commit("login");
        this.$store.commit("changeState", true);

        const keypair = await getKeyPair(this.$axios, config, result.data.token, this.password);
        this.$session.set("keypair", keypair);
        const membershipPreference = localStorage.getItem("membershipPreference");
        const eventCode = localStorage.getItem("eventCode");
        if (eventCode) {
          this.$router.push("/platform/new").catch((e) => e);
        } else if (membershipPreference === "premium") {
          this.$router.push("/platform/premium").catch(() => {});
          localStorage.removeItem("membershipPreference");
        } else {
          const redirect = JSON.parse(localStorage.getItem("redirect"));
          if (redirect) {
            localStorage.removeItem("redirect");
          }
          this.$router.push(redirect || "/platform/bazaar").catch(() => {});
        }
        this.loading = false;
      } else if (!result.status) {
        this.messageBox = {
          content: this.$t("errorMsg.networkError"),
          cssClass: "error",
          state: true,
          destination: ""
        };
        this.loading = false;
      } else if (result.status === 400 || result.status === 401) {
        this.messageBox = {
          content: this.$t(result.data.message),
          cssClass: "error",
          state: true,
          destination: ""
        };
        this.loading = false;
      } else {
        this.messageBox = {
          content: this.$t("errorMsg.login.locked"),
          cssClass: "error",
          state: true,
          destination: ""
        };
        this.loading = false;
      }
    },
    clearError() {
      this.messageBox.state = false;
    }
  }
};
</script>
