<template>
  <div>
    <universal-toolbar :flat="false" :showDrawerMenu="true" />
    <v-main>
      <slot />
    </v-main>
    <v-footer app absolute>
      <Footer />
    </v-footer>
  </div>
</template>

<script>
import Footer from "../components/Common/Footer";
import UniversalToolbar from "../components/Platform/UniversalToolbar.vue";
export default {
  name: "Landing",
  components: {
    Footer,
    UniversalToolbar
  },
  created() {
    if (this.$store.state.setBrowserLanguage) {
      if (navigator.language === "zh-HK" || navigator.language === "zh-TW") {
        this.$i18n.locale = "zhHant";
      } else if (navigator.language === "zh-CN") {
        this.$i18n.locale = "zhHans";
      }
      this.$store.commit("setBrowserLanguage", false);
    }
  }
};
</script>
